import React from 'react';
import clsx from "clsx";

// importing context
import { Message } from "@/contexts/ChatbotContext";

// impporting functions and components
import UserResponse from './UserResponse/UserResponse';
import ChatbotAnswer from "@/components/chatbot/ChatScreen/ChatbotAnswer/ChatbotAnswer";
import ChatbotSignRequest from "@/components/chatbot/ChatScreen/ChatbotSignRequest/ChatbotSignRequest";
import AstrobotLoading from '@/components/chatbot/ChatScreen/AstrobotLoading/AstrobotLoading';
import ShareScreenAnswer from './ShareScreenAnswer/ShareScreenAnswer';

export interface ChatLogProps {
  index: number,
  text: string,
  feedback?: string,
  widgetContainer?: boolean,
  userName?: string,
  shareModal?: boolean,
};
export interface ChatLogsProps {
  messages: Message[],
  userName: string,
  widgetContainer?: boolean;
  isMarketOpen?: boolean;
  shareModal?: boolean,
};

const ChatScreen: React.FC<ChatLogsProps> = ({ messages, userName, widgetContainer = false, isMarketOpen = false, shareModal = false }) => {
  return (
    <div
      className={clsx(
        widgetContainer && 'pt-[15px] pb-[10px] px-[10px] sm:p-[15px]',
        isMarketOpen && 'pt-[20px] px-[10px] lg:px-[20px]',
        !widgetContainer && !isMarketOpen && 'pt-[20px]'
      )}
    >
      {messages?.map((message, index) => {
        return (
          <div key={index}>
            <UserResponse userName={userName} userMessage={message.user} />
            {
              (message.chatbot)
                ? (
                  <>
                    {
                      message.isAuthenticated
                        ? (
                          <>
                            {
                              shareModal
                                ? <ShareScreenAnswer index={index} text={message.chatbot} />
                                : <ChatbotAnswer index={index} text={message.chatbot} feedback={message.feedback} widgetContainer={widgetContainer}/>
                            }
                          </>
                        ) : <ChatbotSignRequest index={index} text={message.chatbot} />
                    }
                  </>
                )
              : <AstrobotLoading />
            }
          </div>
        );
      })}
    </div>
  );
};

export default ChatScreen;