import React from 'react';

// importing components
import { CommonModal } from '@/utils/chatbot/chatbot';

// importing multilanguage
import { useTranslation } from "@/components/translation/TranslationsProvider";

interface PrivacyProp {
  isOpen: boolean;
  onClose: () => void;
};

const PrivacyModal = ({ isOpen, onClose }: PrivacyProp) => {
  const { t } = useTranslation();
  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      heading={t("tmai:privacyPolicy")}
      paraOne={t("tmai:privacyPolicyParaOne")}
      paraTwo={t("tmai:privacyPolicyParaTwo")}
      paraThree={t("tmai:privacyPolicyParaThree")}
      paraFour={t("tmai:privacyPolicyParaFour")}
      paraFive={t("tmai:privacyPolicyParaFive")}
    />
  );
};

export default PrivacyModal;