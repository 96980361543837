import Image from "next/image";

// importing context
import { useChatbotContext } from "@/contexts/ChatbotContext";

// importing ui libraries
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";

// importing extranal libraries
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

// importing images
import MessagesIcon from "@/public/images/market/messages.svg";

// importing icons
import { GoArrowRight } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";
import clsx from "clsx";

// for static quick question on home screen
const RightArrow = () => (
    <span className="bg-[#fff] dark:bg-[#1A1A20] inline-block opacity-0 h-[30px] min-h-[30px] min-w-[30px] flex items-center justify-center rounded-[4px] ml-auto">
        <GoArrowRight className="text-[16px]" />
    </span>
);

const commonQuestionBtnCss =
    "text-[#71717A] dark:text-[#A1A1AA] text-left btn-hover flex justify-start py-[8px] h-auto border border-[#E6E9EF] dark:border-[#414249] bg-[#fff] dark:bg-[#1A1A20] my-[6px] hover:bg-[#F2F4FA] dark:hover:bg-[#111116]";

const BaseQuestionComponent = ({ text, additionalCss }: { text: string; additionalCss: string }) => {
    const { handleChatRequest } = useChatbotContext();

    const handleClick = (text: string) => {
        handleChatRequest(text);
    };

    return (
        <Button className={`${commonQuestionBtnCss} ${additionalCss}`} onClick={() => handleClick(text)}>
            <Image src={MessagesIcon} alt="Icon" height={24} width={24} className="mr-[10px] mb-auto mt-[2px]" />
            <span className="inline-block mr-[5px]">{text}</span>
            <RightArrow />
        </Button>
    );
};

export const LeftSideQuestion = ({ text, className = "" }: { text: string; className?: string }) => (
    <BaseQuestionComponent text={text} additionalCss={`${className} px-[12px] mr-[10px]`} />
);

export const RightSideQuestion = ({ text, className = "" }: { text: string; className?: string }) => (
    <BaseQuestionComponent text={text} additionalCss={`${className} pl-[12px] pr-[10px]`} />
);

// for chat response ui related changes
const a = (props: any) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer" className="underline">
        {props.children}
    </a>
);

const table = (props: any, widgetContainer: boolean) => {
    return (
        <div className={clsx(!widgetContainer ? "w-full" : "w-[329px]", "overflow-x-auto py-4")}>
            <table className="w-full overflow-x-auto rounded-sm border-collapse border border-[#E6E9EF] dark:border-[#414249]">{props.children}</table>
        </div>
    );
};

const thead = (props: any) => {
    return <thead className="rounded-sm">{props.children}</thead>;
};

const th = (props: any) => {
    return (
        <th
            className={`
                text-center 
                bg-[#FFCF30] 
                text-black 
                border-[#E6E9EF] 
                dark:border-[#414249] 
                border-l 
                border-r 
                first:border-l-0 
                last:border-r-0 
                first:rounded-tl-sm 
                last:rounded-tr-sm 
                p-1
            `}
        >
            {props.children}
        </th>
    );
};
const td = (props: any) => {
    return <td className={`text-center border border-[#E6E9EF] dark:border-[#414249] p-1`}>{props.children}</td>;
};
const ul = (props: any) => {
    return <ul className="my-[15px]">{props.children}</ul>;
};
const ol = (props: any) => {
    return <ol className="my-[15px]">{props.children}</ol>;
};

interface ChatResponseProps {
    answer: any;
    widgetContainer?: boolean;
}

export const ChatResponse: React.FC<ChatResponseProps> = ({ answer, widgetContainer = false }) => {
    return (
        <Markdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
                a,
                table: (props) => table(props, widgetContainer),
                thead,
                th,
                td,
                ul,
                ol,
            }}
        >
            {answer}
        </Markdown>
    );
};

interface CommonModalProps {
    isOpen: boolean;
    onClose: () => void;
    heading: string;
    paraOne: string;
    paraTwo: string;
    paraThree: string;
    paraFour: string;
    paraFive: string;
}

export const CommonModal: React.FC<CommonModalProps> = ({ isOpen, onClose, heading, paraOne, paraTwo, paraThree, paraFour, paraFive }) => {
    return (
        <Dialog open={isOpen}>
            <DialogContent className="max-w-[95%] sm:max-w-[400px] md:max-w-[564px] p-0 max-h-[95%] sm:max-h-auto overflow-y-auto">
                <DialogHeader className="">
                    <DialogTitle className="h-[59px] flex justify-between items-center border-b border-[#E7E9EC] dark:border-[#2F3037] px-[10px] sm:px-[20px] sticky top-[-1px] bg-[#fff] dark:bg-[#1A1A20] ">
                        <span data-testid="privacy-modal-heading">{heading}</span>
                        <button onClick={onClose} type="button">
                            <IoCloseOutline className="text-[#A1A1AA] w-5 h-5" data-testid="close-privacy-modal-button" />
                        </button>
                    </DialogTitle>
                    <DialogDescription className="px-[10px] sm:px-[20px] pb-[20px] pt-[15px]">
                        <span className="text-[#71717A] dark:text-[#A1A1AA] text-[14px] font-[400] leading-5 text-left block">{paraOne}</span>
                        <span className="text-[#71717A] dark:text-[#A1A1AA] text-[14px] font-[400] leading-5 pt-[10px] sm:pt-[20px] text-left block">
                            {paraTwo}
                        </span>
                        <span className="text-[#71717A] dark:text-[#A1A1AA] text-[14px] font-[400] leading-5 py-[10px] sm:py-[20px] text-left block">
                            {paraThree}
                        </span>
                        <span className="text-[#71717A] dark:text-[#A1A1AA] text-[14px] font-[400] leading-5 pb-[10px] sm:pb-[20px] text-left block">
                            {paraFour}
                        </span>
                        <span className="text-[#71717A] dark:text-[#A1A1AA] text-[14px] font-[400] leading-5 text-left block">{paraFive}</span>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
};
