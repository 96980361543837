import React, { useState } from 'react';
import Cookies from 'js-cookie';
import Image from 'next/image';
import Link from 'next/link';

// importing ui components
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

// importing components
import TermsModal from '@/components/chatbot/TermsOfUseModal/TermsModal';
import PrivacyModal from '@/components/chatbot/PrivacyPolicyModal/PrivacyModal';
import UseScreenSize from '@/components/UseScreenSize/UseScreenSize';

// importing multilanguage
import { useTranslation } from '@/components/translation/TranslationsProvider';

// importing images
import CodeImg from '@/public/images/chatbot/code.svg';
import ShieldImg from '@/public/images/chatbot/shield.svg';
import Messages from '@/public/images/chatbot/messages.svg';
import ShieldSearch from '@/public/images/chatbot/shield-search.svg';

// importing icons
import { IoCloseOutline } from 'react-icons/io5';

interface DisclaimerProp {
  isOpen: boolean;
  onClose: () => void;
  onAccept?: () => void;
}

const DisclaimerModal = ({ isOpen, onClose, onAccept }: DisclaimerProp) => {
  const { t } = useTranslation();
  const isBelow450px = UseScreenSize({ screenSize: 450 });
  const [step, setStep] = useState<number>(0);
  const [isShowTerm, setIsShowTerm] = useState<boolean>(false);
  const [isShowPrivacy, setIsShowPrivacy] = useState<boolean>(false);

  const handleClose = () => {
    if (step === 1 || step === 2) {
      setStep((prev) => prev - 1);
    } else if (step === 0) {
      window.history.back();
    }
  };

  const handleDone = () => {
    Cookies.set('disclaimerSeen', 'true', { expires: 365 });
    onClose();
    if (onAccept) {
      onAccept();
    }
  };

  const handleTermUse = () => {
    setIsShowTerm(true);
  };

  const handlePrivacyPolicy = () => {
    setIsShowPrivacy(true);
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent className="max-w-[95%] sm:max-w-[450px] p-0 max-h-[95%] sm:max-h-auto overflow-y-auto z-[2147483005]">
        <DialogHeader className="">
          <DialogTitle className="h-[59px] flex justify-between items-center border-b border-[#E7E9EC] dark:border-[#2F3037] px-[10px] sm:px-[20px] sticky top-[-1px] bg-[#fff] dark:bg-[#1A1A20]">
            <span data-testid="disclaimer-modal-main-heading">
              {t('tmai:chatbotMainHeading')}
            </span>
            <button type="button" onClick={handleClose}>
              <IoCloseOutline
                className="text-[#A1A1AA] w-5 h-5"
                data-testid="close-disclaimer-modal-button"
              />
            </button>
          </DialogTitle>
          <DialogDescription
            className="px-[10px] sm:px-[20px] pt-[10px] pb-[20px]"
            asChild
          >
            <div>
              {step === 0 && (
                <div>
                  <h6 className="text-[16px] font-[500] text-left">
                    {t('tmai:disclaimer')}
                  </h6>
                  <p className="text-[#71717A] dark:text-[#A1A1AA] text-[14px] font-[400] pt-[16px] pb-[16px] sm:pb-[20px] text-left">
                    {t('tmai:disclaimerPara')}
                  </p>
                  <div
                    className={`flex items-center ${isBelow450px ? 'flex-col' : 'flex-row'}`}
                  >
                    <Button
                      className={`rounded-[4px] bg-[#F2F4FA] dark:bg-[#111116] border border-[#E6E9EF] dark:border-[#414249] text-[14px] font-[400] ${
                        isBelow450px ? 'w-full' : 'w-[33%]'
                      }`}
                      aria-label="Terms of Use"
                      onClick={handleTermUse}
                      data-testid="disclaimer-modal-terms-button"
                    >
                      {t('tmai:termsOfUse')}
                    </Button>
                    <Button
                      className={`rounded-[4px] bg-[#F2F4FA] dark:bg-[#111116] border border-[#E6E9EF] dark:border-[#414249] text-[14px] font-[400] mx-[16px] ${
                        isBelow450px ? 'w-full my-[10px]' : 'w-[34%]'
                      }`}
                      aria-label="Privacy Policy"
                      onClick={handlePrivacyPolicy}
                      data-testid="disclaimer-modal-privacy-button"
                    >
                      {t('tmai:privacyPolicy')}
                    </Button>
                    <Button
                      className={`bg-[#FFCF30] h-[36px] text-[14px] font-[500] text-[#000] rounded-[4px] ${
                        isBelow450px ? 'w-full' : 'w-[33%]'
                      }`}
                      onClick={() => setStep(1)}
                      data-testid="disclaimer-modal-agree-button"
                    >
                      {t('tmai:agree')}
                    </Button>
                  </div>
                </div>
              )}
              {step === 1 && (
                <div>
                  <h6 className="text-[16px] font-[500] text-left">
                    {t('tmai:howWeCollectData')}
                  </h6>
                  <div className="pt-[16px] pb-[20px]">
                    <div className="flex items-start">
                      <Image
                        src={CodeImg}
                        alt="badge"
                        width={20}
                        height={20}
                        className="mr-[6px]"
                      />
                      <p className="text-[#71717A] dark:text-[#A1A1AA] text-[14px] font-[400] text-left">
                        {t('tmai:howWeCollectDataParaOne')}
                      </p>
                    </div>
                    <Separator className="w-full h-[1px] my-[16px]" />
                    <div className="flex items-start">
                      <Image
                        src={ShieldImg}
                        alt="badge"
                        width={20}
                        height={20}
                        className="mr-[6px]"
                      />
                      <p className="text-[#71717A] dark:text-[#A1A1AA] text-[14px] font-[400] text-left">
                        {t('tmai:howWeCollectDataParaTwo')}
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex justify-end">
                    <Button
                      className="pl-[14px] pr-[10px] py-[9px] rounded-[4px] bg-[#F2F4FA] dark:bg-[#111116] border border-[#E6E9EF] dark:border-[#414249] min-w-[96px] text-[14px] font-[400]"
                      data-testid="disclaimer-modal-next-button"
                      aria-label="Next"
                      onClick={() => setStep(2)}
                    >
                      <span className="inline-block mr-[18px]">
                        {t('tmai:next')}
                      </span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.62109 3.95337L13.6678 8.00004L9.62109 12.0467"
                          stroke="#A1A1AA"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.33203 8H13.552"
                          stroke="#A1A1AA"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div>
                  <h6 className="text-[16px] font-[500] text-left">
                    {t('tmai:researchPreview')}
                  </h6>
                  <div className="pt-[16px] pb-[40px]">
                    <div className="flex items-start">
                      <Image
                        src={ShieldSearch}
                        alt="badge"
                        width={20}
                        height={20}
                        className="mr-[6px]"
                      />
                      <p className="text-[#71717A] dark:text-[#A1A1AA] text-[14px] font-[400] text-left">
                        {t('tmai:researchPreviewParaOne')}
                      </p>
                    </div>
                    <Separator className="w-full h-[1px] my-[16px]" />
                    <div className="flex items-start">
                      <Image
                        src={Messages}
                        alt="badge"
                        width={20}
                        height={20}
                        className="mr-[6px]"
                      />
                      <p className="text-[#71717A] dark:text-[#A1A1AA] text-[14px] font-[400] text-left">
                        {t('tmai:researchPreviewParaTwo')}{' '}
                        <Link
                          href="https://discord.gg/tokenmetricsofficial"
                          target="_blank"
                          className="underline text-[#227AFF]"
                        >
                          {t('tmai:discordServer')}
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between w-full">
                    <Button
                      className="px-[14px] py-[9px] rounded-[4px] bg-[#F2F4FA] dark:bg-[#111116] border border-[#E6E9EF] dark:border-[#414249] min-w-[96px] text-[14px] font-[400]"
                      onClick={() => setStep(1)}
                      aria-label="Back"
                      data-testid="disclaimer-modal-back-button"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.37891 12.0469L2.33224 8.00021L6.37891 3.95354"
                          stroke="#A1A1AA"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.668 8L2.44797 8"
                          stroke="#A1A1AA"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="inline-block ml-[17px]">
                        {t('tmai:back')}
                      </span>
                    </Button>
                    <Button
                      className="bg-[#FFCF30] min-w-[96px] h-[36px] text-[14px] font-[500] text-[#000] rounded-[4px]"
                      onClick={handleDone}
                      aria-label="Done"
                      data-testid="disclaimer-modal-done-button"
                    >
                      {t('tmai:done')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
      <TermsModal isOpen={isShowTerm} onClose={() => setIsShowTerm(false)} />
      <PrivacyModal
        isOpen={isShowPrivacy}
        onClose={() => setIsShowPrivacy(false)}
      />
    </Dialog>
  );
};

export default DisclaimerModal;
