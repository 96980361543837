import Image from "next/image";
import React from "react";

// importing context
import { useChatbotContext } from "@/contexts/ChatbotContext";

// importing ui components
import { Button } from "@/components/ui/button";

// importing functions
import { ChatResponse } from "@/utils/chatbot/chatbot";

// importing types
import { ChatLogProps } from "@/components/chatbot/ChatScreen/ChatScreen";

// importing images
import AskBot from "@/public/images/chatbot/tmai-agent.webp";

// importing icons
import {
  HiOutlineRefresh,
  HiOutlineThumbDown,
  HiOutlineThumbUp,
} from "react-icons/hi";

const ChatbotAnswer: React.FC<ChatLogProps> = ({
  index,
  text,
  feedback,
  shareModal,
  widgetContainer = false,
}) => {
  const { messages, isLocked, updateFeedback, handleChatRequest } =
    useChatbotContext();

  const handleLike = () => {
    updateFeedback(index, "like");
  };

  const handleDislike = () => {
    updateFeedback(index, "dislike");
  };

  const handleRegenerate = () => {
    const question = messages[index].user;
    handleChatRequest(question, index);
  };

  return (
    <div className="mb-[10px] flex md:mb-[20px]">
      <Image
        src={AskBot}
        height={38}
        width={38}
        className="mr-[6px] h-[38px] min-h-[38px] w-[38px] min-w-[38px] rounded-full border border-[#E6E9EF] dark:border-[#414249]"
        alt="askbot"
      />
      <div className="rounded-bl-lg rounded-br-lg rounded-tr-lg border border-[#E6E9EF] bg-[#F2F4FA] px-[12px] py-[8px] text-[14px] font-[400] leading-[26px] dark:border-[#414249] dark:bg-[#111116]">
        <ChatResponse answer={text} widgetContainer={widgetContainer} />
        {!shareModal && (
          <div className="mb-[4px] mt-[12px] flex w-full">
            <Button
              className={`h-[28px] w-[28px] bg-[#ffffff] text-[#71717A] dark:bg-[#1a1a20] dark:text-[#A1A1AA] ${feedback === "like" ? "text-[#03C988] dark:text-[#03C988]" : ""} p-0`}
              onClick={handleLike}
              disabled={isLocked}
              aria-label="like-button"
              data-testid="like-button"
            >
              <HiOutlineThumbUp className="h-4 w-4" />
            </Button>
            <Button
              className={`mx-[10px] h-[28px] w-[28px] bg-[#ffffff] text-[#71717A] dark:bg-[#1a1a20] dark:text-[#A1A1AA] ${feedback === "dislike" ? "text-[#ED2B2A] dark:text-[#ED2B2A]" : ""} p-0`}
              onClick={handleDislike}
              disabled={isLocked}
              aria-label="dislike-button"
              data-testid="dislike-button"
            >
              <HiOutlineThumbDown className="h-4 w-4" />
            </Button>
            <Button
              className="h-[28px] w-[28px] bg-[#ffffff] p-0 text-[#71717A] dark:bg-[#1a1a20] dark:text-[#A1A1AA]"
              onClick={handleRegenerate}
              disabled={isLocked}
              aria-label="regenerate-button"
              data-testid="regenerate-button"
            >
              <HiOutlineRefresh className="h-4 w-4" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatbotAnswer;
