import React from 'react';

// importing components
import { CommonModal } from '@/utils/chatbot/chatbot';

// importing multilanguage
import { useTranslation } from "@/components/translation/TranslationsProvider";

interface TermsProp {
  isOpen: boolean;
  onClose: () => void;
}

const TermsModal = ({ isOpen, onClose }: TermsProp) => {
  const { t } = useTranslation();
  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      heading={t("tmai:termsOfUse")}
      paraOne={t("tmai:termsOfUseParaOne")}
      paraTwo={t("tmai:termsOfUseParaTwo")}
      paraThree={t("tmai:termsOfUseParaThree")}
      paraFour={t("tmai:termsOfUseParaFour")}
      paraFive={t("tmai:termsOfUseParaFive")}
    />
  );
};

export default TermsModal;