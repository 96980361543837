import Image from "next/image";
import React from "react";

// importing functions
import { ChatResponse } from "@/utils/chatbot/chatbot";

// importing types
import { ChatLogProps } from "@/components/chatbot/ChatScreen/ChatScreen";

// importing images
import AskBot from "@/public/images/chatbot/tmai-agent.webp";

const ShareScreenAnswer: React.FC<ChatLogProps> = ({ index, text }) => {
  return (
    <div className="mb-[10px] flex md:mb-[20px]">
      <Image
        src={AskBot}
        height={38}
        width={38}
        className="mr-[6px] h-[38px] min-h-[38px] w-[38px] min-w-[38px] rounded-full border border-[#E6E9EF] dark:border-[#414249]"
        alt="askbot"
      />
      <div className="rounded-bl-lg rounded-br-lg rounded-tr-lg border border-[#E6E9EF] bg-[#F2F4FA] px-[12px] py-[8px] text-[14px] font-[400] leading-[26px] dark:border-[#414249] dark:bg-[#111116]">
        <ChatResponse answer={text} />
      </div>
    </div>
  );
};

export default ShareScreenAnswer;
