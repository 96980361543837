"use client";
import React from 'react';
import Image from "next/image";

// impporting functions and components
import useUser from "@/components/useUser";
import { getInitials } from '@/components/layout/Navbar/Utils/navUtils';

// importing images
import UserLightIcon from "@/public/images/chatbot/User Light Image.png";
import UserDarkIcon from "@/public/images/chatbot/User Dark Image.png";

interface UserResponseProps {
    readonly userName: string;
    readonly userMessage: string;
}

const UserResponse: React.FC<UserResponseProps> = ({ userName, userMessage }) => {
    const user = useUser();
    return (
        <div className='flex mb-[10px] md:mb-[20px]'>
            <div className='mr-[6px] min-h-[38px] h-[38px] w-[38px] min-w-[38px] bg-[#F2F4FA] dark:bg-[#111116] border border-[#E6E9EF] dark:border-[#414249] rounded-full flex items-center justify-center text-[14px] font-[400]'>
                {user?.isLoggedIn ? getInitials(userName) : (
                   <>
                     <Image src={UserLightIcon} alt="User Icon" height={38} width={38} className="dark:hidden"/>
                     <Image src={UserDarkIcon} alt="User Icon" height={38} width={38} className="hidden dark:inline-block"/>
                   </>
                )}
            </div>
            <div className='border border-[#E6E9EF] dark:border-[#414249] text-[14px] font-[400] px-[12px] py-[8px] rounded-tr-lg rounded-br-lg rounded-bl-lg leading-[26px]'>
                {userMessage}
            </div>
        </div>
    );
};

export default UserResponse;