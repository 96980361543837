import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import React from "react";

// importing ui components
import { Button } from "@/components/ui/button";

// importing components
import { ChatResponse } from "@/utils/chatbot/chatbot";

// importing types
import { ChatLogProps } from "@/components/chatbot/ChatScreen/ChatScreen";

// importing for translation
import { useTranslation } from "@/components/translation/TranslationsProvider";

// importing images
import AskBot from "@/public/images/chatbot/tmai-agent.webp";
import RocketImg from "@/public/images/filtersicon/speed.svg";

const ChatbotSignRequest: React.FC<ChatLogProps> = ({
  text,
  widgetContainer = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-[10px] flex flex-col md:mb-[20px]">
      <div className="flex gap-2">
        <Image
          src={AskBot}
          className="mr-1 h-[35px] w-[35px] rounded-[50%]"
          alt="askbot"
        />
        <div className="flex max-w-[1050px] flex-col gap-2 overflow-x-auto rounded-sm rounded-bl-[0] rounded-br-[0] rounded-tr-lg border border-[#E6E9EF] bg-[#F2F4FA] text-[14px] font-[400] dark:border-[#414249] dark:bg-[#111116]">
          <div className="p-2">
            <ChatResponse answer={text} />
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "ml-[47px] flex max-w-[1050px] flex-col items-center justify-center gap-2 overflow-x-auto rounded-bl-lg rounded-br-lg border-b border-l border-r border-[#E6E9EF] bg-[#ffffff] px-2 py-[10px] text-[14px] font-[400] dark:border-[#414249] dark:bg-[#1a1a20]",
          !widgetContainer && "sm:flex-row",
        )}
      >
        <div className="flex flex-row gap-2">
          {!widgetContainer && (
            <Image
              src={RocketImg}
              alt="rocket"
              height={18}
              width={18}
              className="hidden xl:inline-block"
            />
          )}
          <div className="text-black-300 text-md flex items-center text-left">
            {t("tmai:displayBlurChatbotText")}
          </div>
        </div>
        <Link
          href="/signup"
          className={clsx("w-full", !widgetContainer && "sm:w-auto")}
        >
          <Button
            className={clsx(
              "h-[38px] w-full items-center rounded-[4px] bg-[#FFCF30] text-sm text-[#09090B]",
              !widgetContainer && "sm:w-auto sm:min-w-[100px]",
            )}
          >
            {t("navbar:homeHeaderSignup")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ChatbotSignRequest;
