"use client";
import Image from "next/image";
import { useEffect, useMemo, useState } from "react";

// importing multilanguage
import { useTranslation } from "@/components/translation/TranslationsProvider";

// importing images
import AskBot from "@/public/images/chatbot/tmai-agent.webp";

const AstrobotLoading = () => {
  const { t } = useTranslation();

  const loadingLogs = useMemo(
    () => [
      t("tmai:astrobotIsThinking..."),
      t("tmai:scanningDatabase..."),
      t("tmai:analyzing4000Tokens..."),
      t("tmai:crunchingTheNumbers..."),
      t("tmai:formulatingResponse..."),
    ],
    [t],
  );

  const [currentLogIndex, setCurrentLogIndex] = useState(0);
  const [currentLog, setCurrentLog] = useState("");
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    let charTimeout: ReturnType<typeof setTimeout>;
    let logTimeout: ReturnType<typeof setTimeout>;

    if (charIndex < loadingLogs[currentLogIndex].length) {
      charTimeout = setTimeout(() => {
        setCurrentLog(currentLog + loadingLogs[currentLogIndex][charIndex]);
        setCharIndex(charIndex + 1);
      }, 50); // Adjust typing speed here
    } else if (currentLogIndex < loadingLogs.length - 1) {
      logTimeout = setTimeout(() => {
        setCurrentLog("");
        setCharIndex(0);
        setCurrentLogIndex((prev) => prev + 1);
      }, 300); // Delay before showing the next log
    }

    return () => {
      clearTimeout(charTimeout);
      clearTimeout(logTimeout);
    };
  }, [charIndex, currentLog, currentLogIndex, loadingLogs]);

  return (
    <div>
      <div className="mb-[10px] flex items-center">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Astrobot Thinking"
        >
          <path
            d="M20.5 20.5L22 22M4 6V12C4 12 4 15 11 15C18 15 18 12 18 12V6M16 18.5C16 19.163 16.2634 19.7989 16.7322 20.2678C17.2011 20.7366 17.837 21 18.5 21C19.163 21 19.7989 20.7366 20.2678 20.2678C20.7366 19.7989 21 19.163 21 18.5C21 17.837 20.7366 17.2011 20.2678 16.7322C19.7989 16.2634 19.163 16 18.5 16C17.837 16 17.2011 16.2634 16.7322 16.7322C16.2634 17.2011 16 17.837 16 18.5Z"
            stroke="#52C41A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 21C4 21 4 18 4 18V12M11 3C18 3 18 6 18 6C18 6 18 9 11 9C4 9 4 6 4 6C4 6 4 3 11 3Z"
            stroke="#52C41A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span
          className="ml-[6px] inline-block text-[14px] font-[400] text-[#71717A] dark:text-[#A1A1AA]"
          data-testid="astrobot-is-thinking"
        >
          {currentLog}
        </span>
      </div>
      <div className="flex">
        <Image
          src={AskBot}
          height={38}
          width={38}
          className="mr-[6px] h-[38px] min-h-[38px] w-[38px] min-w-[38px] rounded-full border border-[#E6E9EF] dark:border-[#414249]"
          alt="askbot"
        />
        <div className="rounded-bl-lg rounded-br-lg rounded-tr-lg border border-[#E6E9EF] bg-[#F2F4FA] px-[12px] py-[8px] text-[14px] font-[400] leading-[26px] dark:border-[#414249] dark:bg-[#111116]">
          <span className="animate-blink" data-testid="blinking-cursor-loading">
            |
          </span>
        </div>
      </div>
    </div>
  );
};

export default AstrobotLoading;
