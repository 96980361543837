import clsx from "clsx";

// importing components
import useUser from "@/components/useUser";
import { LeftSideQuestion, RightSideQuestion } from "@/utils/chatbot/chatbot";

// importing multilanguage
import { useTranslation } from "@/components/translation/TranslationsProvider";

interface QuickQuestionScreenProps {
  widgetContainer?: boolean;
  isMarketOpen?: boolean;
}

const QuickQuestionScreen = ({
  widgetContainer = false,
  isMarketOpen = false,
}: Readonly<QuickQuestionScreenProps>) => {
  const { t } = useTranslation();
  const user = useUser();
  const userName = user?.name || "";

  return (
    <div
      className={clsx(
        widgetContainer && "p-[10px] sm:p-[15px]",
        isMarketOpen && "px-[10px] py-[16px] lg:px-[20px]",
        !isMarketOpen && !widgetContainer && "py-[10px] sm:py-[20px]",
      )}
    >
      <div>
        <h6 className="text-[18px] font-[600]">
          {t("market:tmAiHeyUserName")}
          {userName ? ` ${userName},` : ","}
        </h6>
        <p className="mt-[5px] text-[14px] font-[400] text-[#71717A] dark:text-[#A1A1AA]">
          {t("market:tmAiAstrobotAsking")}
        </p>
      </div>
      <div
        className={clsx(
          "flex w-full",
          widgetContainer && "mt-[15px] flex-col",
          isMarketOpen && "mt-[8px] flex-col md:flex-row xl:mt-[12px]",
          !widgetContainer &&
            !isMarketOpen &&
            "mt-[10px] flex-col justify-between md:mt-[20px] md:flex-row",
        )}
      >
        <div
          className={clsx(
            "flex w-full flex-col",
            isMarketOpen && "md:w-[50%] xnl:w-auto",
            !widgetContainer &&
              !isMarketOpen &&
              "sm:mb-[5px] md:mb-0 md:w-[49%] xsl:w-auto",
          )}
        >
          <LeftSideQuestion
            text={t("tmai:tmAIQuestionOne")}
            className={clsx(
              widgetContainer && "w-full",
              isMarketOpen &&
                "my-[5px] mr-[10px] w-full sm:my-[10px] xnl:w-[580px]",
              !widgetContainer &&
                !isMarketOpen &&
                "mb-[5px] w-full sm:mb-[10px] xsl:w-[580px]",
            )}
          />
          <LeftSideQuestion
            text={t("tmai:tmAIQuestionThird")}
            className={clsx(
              widgetContainer && "w-full",
              isMarketOpen &&
                "my-[5px] mr-[10px] w-full sm:my-[10px] xnl:w-[580px]",
              !widgetContainer &&
                !isMarketOpen &&
                "mt-[5px] w-full sm:mt-[10px] xsl:w-[580px]",
            )}
          />
        </div>
        <div
          className={clsx(
            "flex w-full flex-col",
            isMarketOpen &&
              "overflow-hidden md:ml-[10px] md:w-[50%] xnl:ml-0 xnl:w-auto",
            !widgetContainer && !isMarketOpen && "md:w-[49%] xsl:w-auto",
          )}
        >
          <RightSideQuestion
            text={t("tmai:tmAIQuestionTwo")}
            className={clsx(
              widgetContainer && "w-full md:ml-0",
              isMarketOpen &&
                "my-[5px] w-full sm:my-[10px] xnl:ml-[10px] xnl:w-[580px]",
              !widgetContainer &&
                !isMarketOpen &&
                "mb-[5px] w-full sm:mb-[10px] xsl:w-[580px]",
            )}
          />
          <RightSideQuestion
            text={t("tmai:tmAIQuestionFourth")}
            className={clsx(
              widgetContainer && "w-full",
              isMarketOpen &&
                "my-[5px] w-full sm:my-[10px] xnl:ml-[10px] xnl:w-[580px]",
              !widgetContainer &&
                !isMarketOpen &&
                "mt-[5px] w-full sm:mt-[10px] xsl:w-[580px]",
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default QuickQuestionScreen;
